<template>
  <div>
    <v-card v-if="itemsToPrint" class="mb-2">
      <v-card-text class="d-flex justify-space-around">
        <v-switch
          v-for="item in computedItemsToPrint"
          :key="item.label"
          :label="item.label"
          :value="item"
          v-model="documentsToReprint"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn
          color="primary"
          @click="reprint()"
          :disabled="documentsToReprint.length === 0"
        >
          Print Selected Items
        </v-btn>
        <v-btn color="primary" @click="reprint(true)"> Print All </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="showControlNo">
      <v-card-text class="flex justify-center">
        <v-form v-model="validControlNo">
          <v-text-field
            type="number"
            @wheel="$event.target.blur()"
            min="0"
            label="Control Number"
            v-model="controlNo"
            :rules="[
              v => (!!v ? true : 'You Must Submit a Control Number'),
              v => (v.length === 8 ? true : 'Control Numbers Must Be 8 Digits')
            ]"
            @keydown.enter="submitControlNo"
          />
        </v-form>
        <v-btn
          color="success"
          @click="submitControlNo"
          :disabled="!validControlNo"
          >Submit</v-btn
        >
      </v-card-text>
    </v-card>
    <v-card v-if="!documentsScanned">
      <v-card-title>Scan Documents</v-card-title>
      <v-card-text>
        <imageScanner @submitClicked="handleScan" />
      </v-card-text>
    </v-card>
    <div class="floating-footer">
      <div class="float-foot-item float-foot-left trans-actions">
        <v-btn
          v-if="fromDeficientList"
          color="red"
          @click="$router.push({ name: 'DeficientTransactions' })"
        >
          Back to Deficients
        </v-btn>
        <v-btn v-else color="red" @click="$router.push({ name: 'Home' })">
          Back to Home
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import transaction from "@/mixins/transaction.mixin";
import imageScanner from "@/components/nonPageComponents/ImageScanner";
import { mapGetters } from "vuex";

export default {
  components: {
    imageScanner
  },
  props: {
    transactionResponse: {
      type: Object,
      required: true
    },
    transactionRequest: {
      type: Object,
      required: true
    },
    itemsToPrint: {
      type: Array,
      required: false
    },
    instaTitlePrintFailure: {
      type: Boolean,
      required: false
    }
  },
  mixins: [transaction],
  data() {
    return {
      controlNo: "",
      validControlNo: false,
      controlNoSubmitted: false,
      documentsScanned: false,
      documentsToReprint: [],
      transObj: JSON.parse(JSON.stringify(this.$store.getters.transObj))
    };
  },
  methods: {
    async submitControlNo() {
      if (/^\d{8}$/.exec(this.controlNo) == null) {
        this.$root.$emit("push-alert", "Invalid Control Number", {
          color: "error"
        });
        return;
      }

      const titleNoResponse = await this.$api.getAssociatedTitleNo(
        this.controlNo
      );
      if (titleNoResponse.length > 0) {
        this.$root.$emit(
          "push-alert",
          "Control Number " + this.controlNo + " is Already Assigned",
          {
            color: "error"
          }
        );
        return;
      }

      try {
        this.$api.submitControlNo(
          this.transactionRequest.title.titleNo,
          this.controlNo
        );
        this.$root.$emit("push-alert", "Control number recorded successfully", {
          color: "success"
        });
        this.controlNoSubmitted = true;
        this.done();
      } catch (e) {
        this.$root.$emit(
          "push-alert",
          "There was a problem with the entered control number",
          { color: "error" }
        );
      }
    },
    async handleScan(str) {
      const dataToSend = {
        transactionID: parseInt(this.transactionResponse.starTransactionID),
        createdUserID: parseInt(this.$store.getters.userObject.id),
        statusCode: "A",
        document: str
      };
      this.$root.$emit("setLoading", true);

      try {
        if (this._.has(this.transactionRequest, "placard", "controlNo"))
          this.$api.submitDocuments(
            dataToSend,
            this.transactionRequest.placard.controlNo
          );
        else this.$api.submitDocuments(dataToSend);
        this.$root.$emit("push-alert", "Documents saved successfully", {
          color: "success"
        });
        this.documentsScanned = true;
        this.done();
      } catch (e) {
        console.log(e);
      } finally {
        this.$root.$emit("setLoading", false);
      }
    },
    done() {
      const canRoute = !this.showControlNo && this.documentsScanned;
      let routeName = "Home";
      if (this.fromDeficientList) {
        routeName = "DeficientTransactions";
      }
      if (canRoute) this.$router.push({ name: routeName });
    },
    reprint(printAll = false) {
      let itemsToPrint = this.documentsToReprint;
      if (printAll) {
        itemsToPrint = this.computedItemsToPrint;
      }
      this.printTransaction(
        itemsToPrint,
        this.transactionRequest,
        this.transactionResponse
      );
    }
  },
  computed: {
    ...mapGetters({
      fromDeficientList: "fromDeficientList"
    }),
    showControlNo() {
      return (
        this.instaTitlePrintFailure !== true &&
        !this.controlNoSubmitted &&
        this.$store.getters.officeConfig.promptForControlNoAfterTransaction &&
        ["DT", "MLH"].includes(
          this.transactionRequest.transaction.transactionType
        )
      );
    },
    computedItemsToPrint() {
      const items = JSON.parse(JSON.stringify(this.itemsToPrint));
      items.forEach(item => {
        item.label = item.doc;
        if (item.doc === "decal") {
          item.label = "Registration Decal";
          items.push({
            label: "Registration Decal (Letter Paper)",
            doc: "decal",
            copies: 1,
            index: 0
          });
        }
      });
      return items;
    }
  },
  created() {
    if (
      [null, undefined, ""].includes(this.transactionResponse) ||
      [null, undefined, ""].includes(this.transactionRequest)
    ) {
      this.$router.push({ name: "Home" });
    }

    if (!this.$store.getters.officeConfig.scanDocumentationAfterTransaction) {
      this.documentsScanned = true;
    }

    this.done();
  },
  watch: {
    barcode() {
      if (this._.has(this.barcode.parses.controlNo)) {
        this.controlNo = this.barcode.parses.controlNo;
      }
      this.submitControlNo();
    }
  },
  beforeRouteLeave(from, to, next) {
    if (this.documentsScanned && !this.showControlNo) {
      next();
      return;
    }
    this.$store.dispatch("setGlobalAlertState", {
      title: "Are you Finished?",
      actions: [
        {
          text: "Yes",
          handler: () => {
            this.$store.dispatch("hideGlobalAlert");
            this.$store.commit("fromDeficientList", false);
            next();
          },
          color: "primary"
        },
        {
          text: "No",
          handler: () => {
            this.$store.dispatch("hideGlobalAlert");
          },
          color: "#E0E0E0"
        }
      ]
    });
  }
};
</script>

<style>
.trans-actions {
  padding: 0px 20px;
}
</style>
